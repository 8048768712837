<template>
  <div>
    <v-form>
      <p class="mb-n3">
        Consider the three following bases: methoxide, ethoxide, and tert-butoxide. How does the
        reactivity of tert-butoxide differ from the other 2 in elimination reactions?
      </p>
      <v-radio-group v-model="inputs.studentAnswer" :disabled="!allowEditing" class="mb-0 pl-6">
        <v-radio
          v-for="option in optionsShuffled"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemUCI51LBA3_Q6',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
      options: [
        {
          text: '$tert$-butoxide is a much weaker base and is incapable of acting as a base in elimination reactions',
          value: 'weaker',
        },
        {
          text: '$tert$-butoxide is much bulkier (sterically) and is thus more likely to form the Hofmann product.',
          value: 'hofmann',
        },
        {
          text: '$tert$-butoxide is much bulkier (sterically) and is thus more likely to form the Zaitsev product.',
          value: 'zaitsev',
        },
        {
          text: '$tert$-butoxide is a much stronger base and thus only follows E1 mechanisms.',
          value: 'stronger',
        },
      ],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
